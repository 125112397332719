<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div style="margin-bottom: 20px; display: flex; align-items: center">
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            @change="pkIdChange"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label for=""
          >登入名：<el-input
            style="width: 150px"
            size="small"
            placeholder="请输入登入名"
            v-model="query.username"
            clearable
        /></label>
        <label style="margin: 0 10px" for=""
          >手机号：<el-input
            style="width: 150px"
            size="small"
            placeholder="请输入手机号"
            v-model="query.mobile"
            clearable
        /></label>
        <label for=""
          >创建时间：<el-date-picker
            style="width: 185px"
            v-model="createTimeBegin"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 185px"
            v-model="createTimeEnd"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            @change="inTimeEndchange"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="querySysAdmincx"
          >查询</el-button
        >
        <el-button size="small" @click="querySysAdmincz">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        >新增</el-button
      >
      <el-button
        type="danger"
        size="small "
        icon="el-icon-delete"
        @click="batchdel"
        >批量删除</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          @selection-change="handleSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="username"
            label="登入名"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="姓名"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkName"
            label="停车场名称"
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ formatTitle(scope.row.pkNameList) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            prop="mobile"
            label="手机号码"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
            sortable
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="lastLoginTime"
            label="最后登录时间"
            sortable
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.lastLoginTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="180"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="ResetPsw(scope)"
                size="mini"
                icon="el-icon-share"
                >重置</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope.row.id)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        v-if="isShowfy"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="添加系统用户"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="登入名" prop="username">
          <el-input
            v-model="addForm.username"
            placeholder="请输入登入名"
          ></el-input>
        </el-form-item>
        <el-form-item label="登入密码" prop="password">
          <el-input
            v-model="addForm.password"
            placeholder="请输入登入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="请再次输入密码" prop="passwords">
          <el-input
            v-model="addForm.passwords"
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="trueName">
          <el-input
            v-model="addForm.trueName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input
            v-model="addForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="系统角色" prop="roleIdList">
          <el-select
            v-model="addForm.roleIdList"
            multiple
            filterable
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="停车场" prop="roleIdList">
          <el-select
            v-model="addForm.pkIdList"
            multiple
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重置 -->
    <el-dialog
      title="重置系统用户"
      :visible.sync="isShowReset"
      @close="ResetClose"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="ResetForm"
        :rules="formRules"
        ref="ResetRef"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="id：" prop="id">
          <el-input v-model="ResetForm.id" placeholder="id"></el-input>
        </el-form-item>
        <el-form-item label="登入密码" prop="password">
          <el-input
            v-model="ResetForm.password"
            placeholder="请输入登入密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowReset = false">取 消</el-button>
        <el-button type="primary" @click="ResetClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="编辑系统用户"
      :visible.sync="isShowEdit"
      width="40%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="editForm.username"
            placeholder="请输入登入名"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="trueName">
          <el-input
            v-model="editForm.trueName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input
            v-model="editForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="系统角色" prop="roleIdList">
          <el-select
            v-model="editForm.roleIdList"
            multiple
            filterable
            placeholder="请输入关键词"
          >
            <el-option
              v-for="item in states"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="停车场" prop="roleIdList">
          <el-select
            v-model="editForm.pkIdList"
            multiple
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findSysAdminPage,
  addSysAdmin,
  getfindAllRoles,
  delSysAdmin,
  editSysAdmin,
  resetPassword,
} from '@/api/system.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import { userGroupTransfer } from '@/api/record.js'
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        // 引入methods中封装的检查手机格式的方法
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === undefined || value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === undefined || value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.addForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isShowData: false,
      isShowAdd: false,
      isShowEdit: false,
      isShowReset: false,
      isShowfy: false,
      tableData: [],
      createTimeBegin: undefined, // 创建时间起始
      createTimeEnd: undefined, // 创建时间截至
      query: {
        // 查询条件
        pk_id: undefined,
        current: 1,
        size: 10,
        username: undefined, // 用户名
        mobile: undefined, // 手机号
        createTimeBegin: undefined, // 创建时间起始
        createTimeEnd: undefined, // 创建时间截至
      },
      total: undefined,
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入登入', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass1,
            trigger: 'blur',
          },
        ],
        passwords: [
          {
            required: true,
            validator: validatePass2,
            trigger: 'blur',
          },
        ],
        trueName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            validator: checkphone,
            trigger: ['blur', 'change'],
          },
        ],
      },
      addForm: {
        // 添加
        username: undefined,
        password: undefined,
        passwords: undefined,
        trueName: undefined,
        mobile: undefined,
        roleIdList: undefined,
        pkIdList: undefined,
      },
      ResetForm: {
        // 添加
        id: undefined,
        password: undefined,
      },
      editForm: {
        // 添加
        id: undefined,
        username: undefined,
        trueName: undefined,
        mobile: undefined,
        roleIdList: undefined,
        pkIdList: undefined,
      },
      states: [], // 保存所有角色
      batchid: '', // 保存批量选中的id
      PkInfoSelect: [], // 保存停车场下拉框
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    pkIdChange(item) {
      this.userGroupTransfer()
    },
    // 获取停车场列表
    userGroupTransfer() {
      userGroupTransfer(
        { pk_id: this.query.pk_id },
        { pk_id: this.query.pk_id }
      ).then((res) => {
        this.userGroupId = res.data.data
      })
    },
    isCellPhone(val) {
      let mobileReg =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/
      if (!mobileReg.test(val)) {
        return false
      } else {
        return true
      }
    },
    getfindAllRoles() {
      // 获取所有系统角色
      getfindAllRoles().then((res) => {
        if (res.data.code === '200') {
          this.states = res.data.data.map((item) => {
            return { value: item.id, label: item.name }
          })
        }
      })
    },
    findSysAdminPage() {
      // 获取资源管理列表
      this.query.createTimeBegin = this.createTimeBegin
      this.query.createTimeEnd = this.createTimeEnd
      if (this.query.createTimeBegin && !this.query.createTimeEnd) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.createTimeBegin && this.query.createTimeEnd) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.createTimeBegin || this.query.createTimeEnd) {
        this.query.createTimeBegin = (
          this.query.createTimeBegin / 1000
        ).toFixed(0)
        this.query.createTimeEnd = (this.query.createTimeEnd / 1000).toFixed(0)
      }
      findSysAdminPage(this.query).then((res) => {
        this.total = res.data.data.total
        if (parseInt(this.total) >= 8) {
          this.isShowfy = true
        }
        this.tableData = res.data.data.list
        this.isShowData = true
      })
    },
    querySysAdmincx() {
      // 查询
      this.query.current = 1
      this.findSysAdminPage()
    },
    querySysAdmincz() {
      this.query.username = undefined
      this.query.mobile = undefined
      this.createTimeBegin = undefined
      this.createTimeEnd = undefined
      this.findSysAdminPage()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 1 }).then((res) => {
        if (res.data.code === '200') {
          this.findSysAdminPage()
          this.getfindAllRoles()
          this.PkInfoSelect = res.data.data
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.query.size = val
      this.findSysAdminPage()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.findSysAdminPage()
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        if (this.addForm.password !== this.addForm.passwords) {
          return this.$message.warning('两次密码不一致')
        }
        addSysAdmin(this.addForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd = false
            this.$message.success('添加成功')
            this.findSysAdminPage()
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框
      editSysAdmin(scope.row.id).then((res) => {
        this.editForm = res.data.data
        this.isShowEdit = true
      })
    },
    editClick() {
      // 编辑
      this.$refs.editRef.validate((valid) => {
        if (!valid) return
        addSysAdmin(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowEdit = false
            this.$message.success('编辑成功')
            this.findSysAdminPage()
          }
        })
      })
    },
    ResetClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.ResetRef.resetFields()
    },
    ResetPsw(scope) {
      // 重置打开弹框
      this.isShowReset = true
      this.ResetForm.id = scope.row.id
    },
    ResetClick() {
      // 重置密码
      resetPassword(this.ResetForm).then((res) => {
        if (res.data.code === '200') {
          this.isShowReset = false
          this.$message.success('重置成功')
          this.findSysAdminPage()
        }
      })
    },
    delClick(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delSysAdmin(id).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.findSysAdminPage()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    handleSelectionChange(val) {
      // 点击table选中
      this.batchid = ''
      val.forEach((item) => {
        this.batchid += item.id + '_'
      })
      this.batchid = this.batchid.slice(0, this.batchid.length - 1)
    },
    batchdel() {
      // 批量删除
      if (this.batchid) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            delSysAdmin(this.batchid).then((res) => {
              if (res.data.code === '200') {
                this.$message.success('删除成功')
                this.findSysAdminPage()
              }
            })
          })
          .catch(() => {
            this.$message.info('已取消删除')
          })
      } else {
        this.$message.warning('请选择需要批量删除的数据')
      }
    },
    // 结束时间选择
    inTimeEndchange() {
      if (this.createTimeBegin === null || this.createTimeBegin === undefined) {
        this.createTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.createTimeEnd < this.createTimeBegin) {
        this.createTimeEnd = this.createTimeBegin + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      }
    },
    // 格式化停车场列表
    formatTitle(value) {
      return value.join(',')
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
